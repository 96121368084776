import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const admin: Routes = [
 {path:'pages/check-website',children:[],loadComponent: () =>
  import('./page/check-website/check-website.component').then((m) => m.CheckWebsiteComponent),},
 {path:'pages/check-website-content',children:[],loadComponent: () =>
  import('./page/check-website-content/check-website-content.component').then((m) => m.CheckWebsiteContentComponent),},
];
@NgModule({
  imports: [RouterModule.forChild(admin)],
  exports: [RouterModule],
})
export class checkWebSiteRoutingModule {
  static routes = admin;
}
export class checkWebSiteContentRoutingModule {
  static routes = admin;
}