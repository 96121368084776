import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../../shared/gaurds/auth.guard';

export const admin: Routes = [
 {path:'pages/report',children:[],loadComponent: () =>
  import('./page/report/report.component').then((m) => m.ReportComponent)}
];
@NgModule({
  imports: [RouterModule.forChild(admin)],
  exports: [RouterModule],
})
export class reportRoutingModule {
  static routes = admin;
}