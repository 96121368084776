import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const admin: Routes = [
 {path:'pages/url-redirect',children:[],loadComponent: () =>
  import('./page/url-redirect/url-redirect.component').then((m) => m.UrlRedirectComponent),}
];
@NgModule({
  imports: [RouterModule.forChild(admin)],
  exports: [RouterModule],
})
export class urlRedirectRoutingModule {
  static routes = admin;
}