import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root' // Angular 6+ way of ensuring a single instance
})
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.isLoggedIn()) {
      request = this.addTokenHeader(request, this.authService.getToken());
    }
    request = request.clone({
      headers: request.headers
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Origin', '*')
    });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string | null): HttpRequest<any> {
    if (!token) {
      return request;
    }
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });
  }

  // private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   // Kiểm tra xem có token hiện tại không
  //   const currentToken = this.authService.getToken(); 
  
  //   if (!this.isRefreshing && currentToken) {
  //     this.isRefreshing = true;
  //     this.refreshTokenSubject.next(null);
  
  //     return this.authService.refreshToken().pipe(
  //       switchMap((token: any) => {
  //         this.isRefreshing = false;
  //         this.refreshTokenSubject.next(token.accessToken);
  //         return next.handle(this.addTokenHeader(request, token.accessToken));
  //       }),
  //       catchError((error) => {
  //         this.isRefreshing = false;
  //         this.authService.logout(); // Đảm bảo người dùng được đăng xuất nếu làm mới token thất bại
  //         return throwError(() => error);
  //       })
  //     );
  //   } else if (!currentToken) {
  //     // Nếu không có token hiện tại, có thể chuyển hướng người dùng đến trang đăng nhập hoặc xử lý khác
  //     this.authService.logout();
  //     return throwError(() => new Error('No token available'));
  //   } else {
  //     // Nếu đang làm mới token, đợi token mới
  //     return this.refreshTokenSubject.pipe(
  //       filter(token => token != null),
  //       take(1),
  //       switchMap(token => {
  //         return next.handle(this.addTokenHeader(request, token));
  //       })
  //     );
  //   }
  // }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Đăng xuất người dùng ngay lập tức khi nhận lỗi 401
    this.authService.logout();
    // Có thể chuyển hướng người dùng đến trang đăng nhập hoặc hiển thị thông báo
    // Điều này phụ thuộc vào cách bạn muốn xử lý sau khi đăng xuất
    return throwError(() => new Error('Unauthorized: No token available or token expired'));
  }
}