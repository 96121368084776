import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const admin: Routes = [
 {path:'pages/url-extract',children:[],loadComponent: () =>
  import('./page/url-extract/url-extract.component').then((m) => m.UrlExtractComponent),}
];
@NgModule({
  imports: [RouterModule.forChild(admin)],
  exports: [RouterModule],
})
export class urlExtractRoutingModule {
  static routes = admin;
}