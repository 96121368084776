import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../../shared/gaurds/auth.guard';

export const admin: Routes = [
 {path:'pages/profile',children:[],loadComponent: () =>
  import('./page/profile/profile.component').then((m) => m.ProfileComponent),canActivate: [AuthGuard]}
];
@NgModule({
  imports: [RouterModule.forChild(admin)],
  exports: [RouterModule],
})
export class profileRoutingModule {
  static routes = admin;
}