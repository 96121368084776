import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../../shared/gaurds/auth.guard';

export const admin: Routes = [
 {path:'pages/compare',children:[],loadComponent: () =>
  import('./page/compare/compare.component').then((m) => m.CompareComponent),canActivate: [AuthGuard]}
];
@NgModule({
  imports: [RouterModule.forChild(admin)],
  exports: [RouterModule],
})
export class compareRoutingModule {
  static routes = admin;
}