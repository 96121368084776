import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const admin: Routes = [
 {path:'pages/whitelist',children:[],loadComponent: () =>
  import('./page/whitelist/whitelist.component').then((m) => m.WhitellistComponent),}
];
@NgModule({
  imports: [RouterModule.forChild(admin)],
  exports: [RouterModule],
})
export class whitelistRoutingModule {
  static routes = admin;
}