import { Route } from '@angular/router';
import { ContentLayoutComponent } from './shared/layouts/content-layout/content-layout.component';
import { content } from './shared/routes/content.routes';
import { AuthGuard } from './shared/gaurds/auth.guard';

export const App_Route: Route[] = [
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  {
    path: 'auth/login',
    loadComponent: () =>
      import('../app/authentication/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: 'auth/signup',
    loadComponent: () =>
      import('../app/authentication/signup/basic/basic.component').then((m) => m.SignUpComponent),
  },
  {
    path: 'pages/check-website-domain',
    loadComponent: () =>
      import('../app/components/pages/check-website/page/check-domain/check-domain.component').then((m) => m.CheckDomainComponent),
  },
  {
    path: 'pages/whitelist',
    loadComponent: () =>
      import('../app/components/pages/whitelist/page/whitelist/whitelist.component').then((m) => m.WhitellistComponent),
  },
  {
    path: '',
    component: ContentLayoutComponent,
    children: content,
    canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: 'auth/login' },
];