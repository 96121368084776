import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { admin, dashboardRoutingModule } from '../../components/dashboards/dashboard.routes';
import { pagesRoutingModule } from '../../components/pages/pages.routes';
import { filemanagerRoutingModule } from '../../components/pages/file-manager/filemanager.routes';
import { seaerchEngineRoutingModule } from '../../components/pages/search-engine/search-engine.routes';
import { urlExtractRoutingModule } from '../../components/pages/url-extract/url-extract.routes';
import { urlRedirectRoutingModule } from '../../components/pages/url-redirect/url-redirect.routes';
import { checkWebSiteRoutingModule } from '../../components/pages/check-website/check-website.routes';
import { checkWebSiteContentRoutingModule } from '../../components/pages/check-website/check-website.routes';
import { compareRoutingModule } from '../../components/pages/compare/compare.routes';
import { whitelistRoutingModule } from '../../components/pages/whitelist/whitelist.routes';
import { profileRoutingModule } from '../../components/pages/profile/profile.routes';
import { reportRoutingModule } from '../../components/pages/report/report.routes';
import { AuthGuard } from '../gaurds/auth.guard';


export const content: Routes = [

  { path: '', children: [
   ...dashboardRoutingModule.routes,
      // ...pagesRoutingModule.routes,
      // ...filemanagerRoutingModule.routes,
      ...seaerchEngineRoutingModule.routes,
      ...urlExtractRoutingModule.routes,
      ...urlRedirectRoutingModule.routes,
      ...checkWebSiteRoutingModule.routes,
      ...checkWebSiteContentRoutingModule.routes,
      ...compareRoutingModule.routes,
      ...whitelistRoutingModule.routes,
      ...profileRoutingModule.routes,
      ...reportRoutingModule.routes,
  ], canActivate: []}

  
];
@NgModule({
    imports: [RouterModule.forRoot(admin)],
    exports: [RouterModule]
})
export class SaredRoutingModule { }
